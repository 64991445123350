<div class="container footer text-muted">
  <div class="row">
    <div class="col">&copy; {{ year }} Vaultwarden.</div>
    <div class="col text-center"></div>
    <div class="col text-right">
      {{ "versionNumber" | i18n: version }}
      <br/>
      Mon Mar 27 06:54:38 UTC 2023
      <br/>
      1258799233e9d3350b7b99767a91921fda5441d3
    </div>
  </div>
</div>
